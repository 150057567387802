<template>
    <div class="scoreboard">
        <h2 class="title-h1">
{{ i18n('Scoreboard') }}
</h2>
        <table>
            <thead>
            <tr>
                <th>{{ i18n('Ranking') }}</th>
                <th>{{ i18n('Name') }}</th>
                <th>{{ i18n('Score') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(score, index) in scores" :key="index"
                :class="index % 2 === 0 ? 'even' : 'odd'">
                <td>{{ index + 1 }}</td>
                <td>{{ score.name }}</td>
                <td>{{ score.score }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'UserIndex',
    inject: ['i18n', 'http', 'route'],
    data() {
        return {
            scores: [],
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.http
                .get(this.route('score.userScore'))
                .then(response => {
                    this.scores = response.data.score.sort((a, b) => b.score - a.score);
                })
                .catch(this.errorHandler);
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../sass/enso.scss';
.scoreboard {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}
.title-h1{
    font-size: 30px;
    color: $primary;
    text-align: center;
}

th {
    padding: 10px;
    font-weight: bold;
    text-transform: uppercase;
}

td {
    padding: 10px;
    border-bottom: 1px solid ;
}
.odd {
    background-color: rgba(255, 255, 255, 0.06);
}

@media only screen and (max-width: 600px) {
    th, td {
        font-size: 14px;
    }
}
</style>
